<template>
    <div class="header">
        <div class="header-logo" v-on:click="clickButton('NewsButton')">
            <img src="logo horizontal.svg">
        </div>

        <div class="header-buttons-window">
            <div class="header-buttons-window-text">
                <div v-html="getText('news ')" v-on:click="clickButton('NewsButton')"></div>

                <div v-html="getText('about ')" v-on:click="clickButton('BioButton')"></div>

                <div v-html="getText('discography ')" v-on:click="clickButton('DiscographyButton')"></div>

                <div v-html="getText('contact')" v-on:click="clickButton('ContactButton')"></div>
            </div>
        </div>

        <hr/>
    </div>
</template>

<script>
    import helpers from '../helpers/helpers.js';

    export default {
        name: 'HeaderBar',
        data() {
            return {
                clickedButton: null
            }
        },
        methods: {
            clickButton(button) {
                this.clickedButton = button;
                this.$emit("clicked-header-button", this.clickedButton);
            },
            getText(text) {
                return helpers.textToCSV(text);
            }
        }
    }
</script>
