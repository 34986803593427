import { createApp } from 'vue'
import App from './App.vue'

import './assets/css/style.css'
import './assets/css/style0to400.css'
import './assets/css/style400to600.css'
import './assets/css/style600to800.css'
import './assets/css/style800to1000.css'
import './assets/css/style1000to1300.css'

createApp(App).mount('#app')
