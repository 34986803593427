<template>
    <div>
        <div class="social-media-div">

            <a v-if="showEmail" href="mailto: contact@stickfiguremagic.com" target="_blank">
                <img src="Logos/email.svg">
            </a>

            <a href="https://www.instagram.com/stickfiguremagic" target="_blank">
                <img src="Logos/instagram.svg">
            </a>

            <a href="https://twitter.com/StckFigureMagic" target="_blank">
                <img src="Logos/twitterx.svg">
            </a>

        </div>
    </div>

</template>

<script>

    export default {
        name: 'SocialMediaDiv',
        props: {
            showEmail: Boolean
        },
        /*data() {
            return {
                showEmail: true
            }
        },*/
        methods: {
            clickButton(buttonClicked) {
                this.clickedButton = buttonClicked;
            },
        }
    }
</script>

