<template>
    <div class="content-window lyrics-window">
        <div class="lyrics-title">
            <div v-html="getTitle()"/> 
        </div>
        <div class="lyrics-text">
            <div v-html="getText()"/> 
        </div>
    </div>
</template>

<script>
    import helpers from '../helpers/helpers.js';

    import papers from 'raw-loader!../text/./papers.txt';
    import stranger from 'raw-loader!../text/./stranger.txt';
    import dirtyFace from 'raw-loader!../text/./dirty face.txt';
    import danceMonkey from 'raw-loader!../text/./dance monkey.txt';
    import gone from 'raw-loader!../text/./gone.txt';
    import factor from 'raw-loader!../text/./factor.txt';
    import writersBlock from 'raw-loader!../text/./writers block.txt';
    
    export default {
        name: 'LyricsTab',
        data() {
            return {
                title: "",
                innerWidth: window.innerWidth
            }
        },
        props: {
            textFromParent: String
        },
        mounted() {
            window.addEventListener("resize", this.myEventHandler);
        },
        unmounted() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        methods: {
            myEventHandler() {
                this.innerWidth = window.innerWidth;
            },
            getTitle() {
                return this.title;
            },
            getText() {
                let lines = "";
                if(this.textFromParent.includes("papers")) {
                    lines = papers.split("\n");
                } else if(this.textFromParent.includes("stranger")) {
                    lines = stranger.split("\n");
                } else if(this.textFromParent.includes("dirtyFace")) {
                    lines = dirtyFace.split("\n");
                } else if(this.textFromParent.includes("danceMonkey")) {
                    lines = danceMonkey.split("\n");
                } else if(this.textFromParent.includes("gone")) {
                    lines = gone.split("\n");
                } else if(this.textFromParent.includes("factor")) {
                    lines = factor.split("\n");
                } else if(this.textFromParent.includes("writersBlock")) {
                    lines = writersBlock.split("\n");
                }

                this.title = helpers.textToCSV(lines[0]);

                return this.getLyricsWithNewLines(lines);
            },
            getMaxLength() {
                if (this.innerWidth < 400) {
                    return 30;
                } else if (this.innerWidth >= 400 && this.innerWidth < 600) {
                    return 35;
                } else if (this.innerWidth >= 600 && this.innerWidth < 800) {
                    return 40;
                } else if (this.innerWidth >= 800 && this.innerWidth < 1000) {
                    return 45;
                } else if (this.innerWidth >= 1000 && this.innerWidth < 1300) {
                    return 55;
                }  else {
                    return 80;
                }
            },
            getLyricsWithNewLines(lines) {
                let retunString = "";
                let maxLength = this.getMaxLength();
                for (let index = 1; index < lines.length; index++) {
                    retunString += this.getTextSplitByMaxLength(lines[index], maxLength);
                }
                return retunString;
            },
            getTextSplitByMaxLength(text, maxLength) {
                let words = text.split(" ");
                let asdf = "<br>";
                let totalLength = 0;
                for (let index = 0; index < words.length; index++) {
                    totalLength += words[index].length;

                    const element = helpers.textToCSV(words[index] + " ");
                    if(totalLength > maxLength) {
                        asdf += "<br>" + element;
                        totalLength = words[index].length;
                    } else {
                        asdf += element;
                        totalLength += 1; //plus 1 for the space
                    }
                }
                return asdf;
            }
        }
    }
</script>
