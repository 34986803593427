<template>
    <HeaderBar v-on:clicked-header-button="newEvent($event)"></HeaderBar>
    <QuotesTab v-if="showInMain == 'QuotesButton'"></QuotesTab>
    <NewsTab v-if="showInMain == 'NewsButton'"></NewsTab>
    <BioTab v-if="showInMain == 'BioButton'"></BioTab>
    <DiscographyTab v-if="showInMain == 'DiscographyButton'"
        v-on:clicked-discography-button="newEvent($event)"></DiscographyTab>
    <ContactTab v-if="showInMain == 'ContactButton'"></ContactTab>
    <LyricsTab v-if="showLyrics()" :textFromParent="showInMain"></LyricsTab>
    <FooterBar></FooterBar>
</template>

<script>
    import HeaderBar from './components/HeaderBar.vue'
    import QuotesTab from './components/QuotesTab.vue'
    import FooterBar from './components/FooterBar.vue'
    import NewsTab from './components/NewsTab.vue'
    import BioTab from './components/BioTab.vue'
    import DiscographyTab from './components/DiscographyTab.vue'
    import ContactTab from './components/ContactTab.vue'
    import LyricsTab from './components/LyricsTab.vue'

    export default {
        name: 'App',
        components: {
            HeaderBar,
            QuotesTab,
            FooterBar,
            NewsTab,
            BioTab,
            DiscographyTab,
            ContactTab,
            LyricsTab
        },
        data() {
            return {
                showInMain: "NewsButton" //QuotesButton för socialmedia bilder
            }
        },
        methods: {
            newEvent(event) {
                this.showInMain = event;
            },
            showLyrics() {
                let lyrics = [
                    "papers", "stranger", "dirtyFace", "danceMonkey",
                    "gone", "factor", "writersBlock"
                ];
                return lyrics.includes(this.showInMain);
            }
        }
    }
</script>
