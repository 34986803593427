<template>
    <div>
        <div class="news1-grid">
            <div class="news1-header">
                <div v-html="getText('New album out now!')"/> 
            </div>

            <div class="news1-cover">
                <img src="fragments cover.svg">
            </div>

            <div class="news1-stream1">
                <a href="https://open.spotify.com/album/7slOxS0QFuMxPjUu7iF6wc?si=T2r6DBoXSSSNDo6MnwhU1Q" target="_blank">
                    <div class="news1-text">
                        <div class="news1-text-logo"><img src="Logos/Spotify_black.svg"></div>
                        <div v-html="getText(' stream on spotify')"/> 
                    </div>
                </a>
            </div>

            <div class="news1-stream2">
                <a href="http://itunes.apple.com/album/id/1722425320" target="_blank">
                    <div class="news1-text">
                        <div class="news1-text-logo"><img src="Logos/AppleMusic.svg"></div>
                        <div v-html="getText(' stream on Apple Music')"/> 
                    </div>
                </a>
            </div>

            <div class="news1-stream3">
                <a href="https://music.amazon.com/albums/B0CQRDGFV1" target="_blank">
                    <div class="news1-text">
                        <div class="news1-text-logo"><img src="Logos/Amazon.svg"></div>
                        <div v-html="getText(' stream on Amazon')"/> 
                    </div>
                </a>
            </div>

            <div class="news1-stream4">
                <a href="https://music.youtube.com/playlist?list=OLAK5uy_lgYmowN5ooa0BDlXUDvTNB1-5fMU7B1VM" target="_blank">
                    <div class="news1-text">
                        <div class="news1-text-logo"><img src="Logos/Youtube.svg"></div>
                        <div v-html="getText(' stream on Youtube Music')"/> 
                    </div>
                </a>
            </div>

            <div class="news1-stream5">
                <a href="https://www.deezer.com/en/album/526380232" target="_blank">
                    <div class="news1-text">
                        <div class="news1-text-logo"><img src="Logos/Deezer.svg"></div>
                        <div v-html="getText(' stream on Deezer')"/> 
                    </div>
                </a>
            </div>

        </div>

    </div>

</template>

<script>
    import helpers from '../../helpers/helpers.js';

    export default {
        name: 'NewsComponent1',
        data() {
            return {
                buttonClicked: null
            }
        },
        methods: {
            getText(text) {
                return helpers.textToCSV(text);
            },
            clickButton(buttonClicked) {
                this.clickedButton = buttonClicked;
            },
        }
    }
</script>

