<template>
    <div class="content-window">

        <div class="contact-grid">
            <div class="contact-text">
                <div v-html="getContactText1()"/> 
            </div>
            <div class="contact-text contact-email">
                <div v-html="getContactText2()"/> 
            </div>
            <div class="contact-text">
                <div v-html="getSocialMediaText()"/> 
            </div>
            <div class="contact-social">
                <SocialMediaDiv :showEmail="false"></SocialMediaDiv>
            </div>
        </div>

    </div>
</template>

<script>
    import helpers from '../helpers/helpers.js';
    import SocialMediaDiv from './SocialMediaDiv.vue';
    
    export default {
        name: 'ContactTab',
        components: {
            SocialMediaDiv
        },
        data() {
            return {
                text: ""
            }
        },
        methods: {
            getText(text) {
                return helpers.textToCSV(text);
            },
            getContactText1() {
                return helpers.textToCSV("email:");
            },
            getContactText2() {
                return "<a href = \"mailto: contact@stickfiguremagic.com\">" +
                helpers.textToCSV("contact@stickfiguremagic.com") +
                "</a>";
            },
            getSocialMediaText() {
                return helpers.textToCSV("social media:");
            }
        }
    }
</script>
