<template>
    <div class="content-window">
        <div class="quotes-text">
            <div v-html="getText('Have you read the papers lately?')"/> 
        </div>
    </div>
</template>

<script>
    import helpers from '../helpers/helpers.js';
    
    export default {
        name: 'QuotesTab',
        data() {
            return {
                text: ""
            }
        },
        methods: {
            getText(text) {
                return helpers.textToCSV(text);
            },
        }
    }
</script>
