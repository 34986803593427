<template>
    <div class="footer">
        <div class="footer-line">
            <hr/>
        </div>
        
        <div class="footer-content">
            <SocialMediaDiv :showEmail="true"></SocialMediaDiv>
        </div>
    </div>
</template>

<script>
    import helpers from '../helpers/helpers.js';
    import SocialMediaDiv from './SocialMediaDiv.vue';

    export default {
        name: 'FooterBar',
        components: {
            SocialMediaDiv
        },
        data() {
            return {
                clickedButton: null
            }
        },
        methods: {
            clickButton(button) {
                this.clickedButton = button;
                this.$emit("clicked-header-button", this.clickedButton);
            },
            getText(text) {
                return helpers.textToCSV(text);
            }
        }
    }
</script>
