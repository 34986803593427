<template>
    <div class="content-window">

        <NewsComponent1></NewsComponent1>

    </div>

</template>

<script>
    //import helpers from '../helpers/helpers.js';
    import NewsComponent1 from './News/NewsComponent1.vue'
    
    export default {
        name: 'NewsTab',
        components: {
            NewsComponent1
        },
        data() {
            return {
                text: ""
            }
        },
        methods: {
            getNews1() {
                //return  helpers.textToCSV("Debut album out soon!!!");
            }
        }
    }
</script>
